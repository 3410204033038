import React from 'react';
import { Heading, Logo, Wrap } from '@leafwell/components';

const Loader: React.FC<{ className?: string; fullHeight?: boolean }> = ({
  className = '',
  fullHeight = true,
}) => (
  <Wrap
    className={[
      'text-center justify-items-center content-center !gap-y-3',
      fullHeight ? 'h-screen' : '',
      className,
    ].join(' ')}
  >
    <Logo variant="icon" width={fullHeight ? 80 : 30} />
    <Heading level="5">loading...</Heading>
  </Wrap>
);

export default Loader;
