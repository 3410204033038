import type { GetStaticProps } from 'next';
import React from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Logo, Wrap, ToastProvider } from '@leafwell/components';
import Blocks from 'components/blocks';
import HeroCard from 'components/hero-card';
import Loader from 'components/loader';
import { getUsLandingPage } from 'lib/queries/us-landings';
import { PageProps } from 'lib/queries/pages';

const UsLandingHome: React.FC<PageProps> = ({
  page: {
    modified,
    acf: { hero, redirectCannabisCounseling, blocks, pageType, states },
  },
  partnersReviews,
}) => {
  const siteTitle = 'Leafwell';

  const { isFallback } = useRouter();
  const cardPageSettings = {
    affiliateWebsite: null,
    affiliateLogo: null,
    formUrl: null,
    vpdPage: null,
    pauseAppointments: false,
    redirectMedicalCard: true,
    totalMedicalCardPrice: null,
    utmParams: {
      utm_campaign: 'mc_embed',
      utm_medium: 'satellite',
      utm_source: 'leafwell',
    },
    name: null,
    description: null,
    imageObjectCaption: null,
    product: {
      name: null,
      description: null,
      imageUrl: null,
    },
    isRenewal: false,
    stateProductImage: null,
    vpdPartnerName: null,
    vpdLogo: null,
    price: {
      price: null,
      newPrice: null,
      percentageDiscount: null,
      discountCode: null,
    },
    customStateCode: null,
  };

  if (isFallback) {
    return <Loader />;
  }

  return (
    <>
      <NextSeo
        key="us-landing-home"
        title={siteTitle}
        nofollow={true}
        noindex={true}
        robotsProps={{ noarchive: true }}
      />
      <ToastProvider>
        <Wrap className="!py-6">
          <Logo
            {...{
              classNameButton: 'w-32 xl:w-40 z-10 lg:z-20',
              href: '',
              title: siteTitle,
            }}
          />
        </Wrap>
        <HeroCard
          hero={hero}
          states={states}
          showBadges={false}
          showStateSelect={false}
          spacer={false}
          redirectStatePage={true}
          showTrustpilot={true}
          pageType={pageType}
          formUrl={cardPageSettings.formUrl}
          affiliateLogo={cardPageSettings.affiliateLogo}
          affiliateWebsite={cardPageSettings.affiliateWebsite}
          pauseAppointments={cardPageSettings.pauseAppointments}
          redirectMedicalCard={cardPageSettings.redirectMedicalCard}
          redirectCannabisCounseling={redirectCannabisCounseling}
          totalMedicalCardPrice={cardPageSettings.totalMedicalCardPrice}
        />
        {blocks.length > 0 ? (
          <Blocks
            blocks={blocks}
            modified={modified}
            pageType={pageType}
            states={states}
            partnersReviews={partnersReviews}
          />
        ) : null}
      </ToastProvider>
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  return await getUsLandingPage(context);
};

export default UsLandingHome;
